import {
  CommentBox,
  InputBox,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Access,
  ACCESS_ID,
  Base,
  BASE_CODE,
  ContractUsage,
  FetchAccessesRequest,
  FetchContractUsageRequest,
  SaveAccessRequest,
  toLatestUserPath,
  User
} from '@atomica.co/irori';
import { builder, embedIdInPath, EMPTY, Language, Message, noop, Offset, uuid, ZERO } from '@atomica.co/utils';
import { List as MuiList, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React, { useEffect, useMemo, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { ContractPlan, toContractPlanLabel } from '../../constants/base-config';
import { MEMBER_ONLY, NO_BASE } from '../../constants/error-message-const';
import { returnBaseOnlyIfExisted } from '../../converters/shot-usage-converter';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import AccessRequest from '../../requests/access-request';
import ContractRequest from '../../requests/contract-request';
import { Path, PATH_IDS } from '../../router/Routes';
import { ACCESS_LABELS } from '../../texts/access-text';
import { isTimeBasedPlan } from '../../utils/contract-util';
import { toTimeStr } from '../../utils/date-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';
import { default as PrimaryRow } from './access-row/AccessRow';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P extends RouteComponentProps {}

const RecordAccessScreen: React.FC<P> = React.memo(() => {
  const { params, queryParams, openPath } = usePath();
  const { getUser } = useUser();
  const bottomRef = useRef<HTMLDivElement>();
  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);
  const [signInUser, setSignInUser] = useSafeState<User>(unmountRef);
  const [usageSummary, setUsageSummary] = useSafeState<ContractUsage | undefined>(unmountRef);
  const [currentAccess, setCurrentAccess] = useSafeState<Access>(unmountRef);
  const [pastAccesses, setPastAccesses] = useSafeState<Access[]>(unmountRef, []);

  const base = useMemo<Base | undefined>(() => {
    return returnBaseOnlyIfExisted(params[BASE_CODE]);
  }, [params]);

  const loadAccesses = useSafeCallback(async (user: User): Promise<Access[]> => {
    if (!hasMore) return [];

    const request = builder<FetchAccessesRequest>().userId(user.userId).limit(LIMIT).offset(offset.current).build();

    const response = await AccessRequest.fetchAccesses(request);
    const pastAccesses = response.accesses;

    offset.current += LIMIT;
    hasMore.current = pastAccesses.length === LIMIT;
    return pastAccesses;
  }, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!base) {
      setErrorMessage(NO_BASE);
      return;
    }

    const signInUser = (await getUser()) as User;

    if (!signInUser.contract) {
      setErrorMessage(MEMBER_ONLY);
      return;
    }

    const prevAccessId = queryParams[ACCESS_ID];

    if (!prevAccessId) {
      const newAccessId = uuid();
      const request = builder<SaveAccessRequest>().accessId(newAccessId).base(base).user(signInUser).build();

      const response = await AccessRequest.saveAccess(request);
      !!response.accessId && database.ref(toLatestUserPath(base)).set({ accessId: newAccessId });
      !!response.accessId &&
        openPath(`${embedIdInPath(Path.RECORD_ACCESS, PATH_IDS, [base])}?${ACCESS_ID}=${newAccessId}` as any);
    }

    const request = builder<FetchContractUsageRequest>().userId(signInUser.userId).build();

    const [usageResponse, pastAccesses] = await Promise.all([
      ContractRequest.fetchContractUsage(request),
      loadAccesses(signInUser)
    ]);

    setSignInUser(signInUser);
    setUsageSummary(usageResponse.usage);
    setCurrentAccess(pastAccesses[0]);
    setPastAccesses(pastAccesses);
    setLoaded(true);
  }, [
    base,
    queryParams,
    getUser,
    setErrorMessage,
    openPath,
    loadAccesses,
    setSignInUser,
    setUsageSummary,
    setCurrentAccess,
    setPastAccesses,
    setLoaded
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        const accessesToAdd = await loadAccesses(signInUser);
        setPastAccesses(prevAccesses => [...prevAccesses, ...accessesToAdd]);
      }
    },
    [loadAccesses, signInUser, setPastAccesses]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    bottomRef.current && observer.observe(bottomRef.current);
    return observer.disconnect();
  }, [loaded, onScroll]);

  return (
    <Screen errorMsg={errorMessage} loading={!loaded} className='record-access-screen'>
      <Container>
        <Content>
          <MojacoWrapper>
            <CommentBox animation photoURL={mojaco_greeting}>
              <Greeting>
                {!!currentAccess ? `${ACCESS_LABELS[currentAccess.accessType]}の時間を記録したよ！` : EMPTY}
              </Greeting>
            </CommentBox>
          </MojacoWrapper>

          {!!base && !!signInUser && !!signInUser.contract && (
            <InputWrapper>
              <InputBox
                editable={false}
                shape='rect'
                type='text'
                label='ご契約プラン'
                text={toContractPlanLabel(base, signInUser.contract.contractPlan as ContractPlan)}
                onChange={noop}
              />
            </InputWrapper>
          )}

          {!!signInUser && isTimeBasedPlan(signInUser.contract) && !!usageSummary && !!usageSummary.usageMsec && (
            <InputWrapper>
              <InputBox
                editable={false}
                shape='rect'
                type='text'
                label='今月のご利用時間'
                text={toTimeStr(usageSummary.usageMsec, Language.JAPANESE)}
                onChange={noop}
              />
            </InputWrapper>
          )}

          {!!signInUser && isTimeBasedPlan(signInUser.contract) && !!usageSummary && !!usageSummary.remainingMsec && (
            <InputWrapper>
              <InputBox
                editable={false}
                shape='rect'
                type='text'
                label='今月の残り時間'
                text={toTimeStr(usageSummary.remainingMsec, Language.JAPANESE)}
                onChange={noop}
              />
            </InputWrapper>
          )}

          <ListWrapper>
            <Label>入退室履歴</Label>

            <List>
              {pastAccesses.map((prevAccess, index) => (
                <ListItem key={index} onClick={noop}>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>

                  <ListItemText primary={<PrimaryRow access={prevAccess} />} />
                </ListItem>
              ))}
            </List>
          </ListWrapper>

          <Bottom ref={bottomRef} />
        </Content>
      </Container>
    </Screen>
  );
});

export default RecordAccessScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  scroll-behavior: smooth;
`;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 2}px;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0px 0px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
`;

const List = styled(MuiList)`
  background: ${theme.mixins.background.white};
  border-radius: ${theme.mixins.spacing / 2}px;
  padding: 0px;
`;

const Bottom = styled.div``;
