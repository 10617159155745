import { Path } from '../router/Routes';
import { SignUpInTabEnum } from '../screens/sign-up-in/tabs/SignUpInTabs';

export const isAuthGuarded = (path: Path): boolean => {
  switch (path) {
    case Path.SIGN_UP:
    case Path.SIGN_IN:
    case Path.LIFF:
    case Path.REGISTER_ACCOUNT:
    case Path.SIGN_OUT:
    case Path.REDIRECT:
    case Path.RESERVE_SPACE:
      return false;

    // case Path.ACCOUNT:
    // case Path.SELECT_BASE:
    // case Path.SETTING:
    case Path.MY_ACCOUNT:
    case Path.REGISTER_CONTRACT_MEMBER:
    case Path.REGISTER_SHOT:
    case Path.SHOT_RECEPTION:
    case Path.RECORD_ACCESS:
    case Path.JOIN_EVENT:
    case Path.TIMELINE:
    case Path.VISITORS:
    case Path.USER_SEARCH:
    case Path.USER_DETAILS:
    case Path.CONTRACT_LIST:
    case Path.CONTRACT_DETAILS:
    case Path.REGISTER_CONTRACT:
    case Path.EVENT_LIST:
    case Path.EVENT_V2_LIST:
    case Path.REGISTER_EVENT_V2:
    case Path.EVENT_V2_DETAILS:
    case Path.DIARY:
    case Path.EXPORT:
    case Path.REGISTERED:
      return true;

    default:
      return false;
  }
};

export const toSignUpInTab = (path: Path): SignUpInTabEnum => {
  switch (path) {
    case Path.SIGN_UP:
      return SignUpInTabEnum.SIGN_UP;

    case Path.SIGN_IN:
      return SignUpInTabEnum.SIGN_IN;

    default:
      return SignUpInTabEnum.OUT_OF_TARGET;
  }
};

export const fromSignUpInTab = (tab: SignUpInTabEnum): Path | undefined => {
  switch (tab) {
    case SignUpInTabEnum.SIGN_UP:
      return Path.SIGN_UP;

    case SignUpInTabEnum.SIGN_IN:
      return Path.SIGN_IN;
  }
};
