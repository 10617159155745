import { ShotPaymentMethod } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const NUMBER_OF_PEOPLE_LABEL_ONE_DAY = '1DAY利用人数';

export const PAYMENT_METHOD_LABELS: Labels = {
  [ShotPaymentMethod.CASH]: '現金',
  [ShotPaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [ShotPaymentMethod.E_MONEY]: '電子マネー',
  [ShotPaymentMethod.QR]: 'QRコード',
  [ShotPaymentMethod.ID]: 'iD',
  [ShotPaymentMethod.QUICK_PAY]: 'QuickPay',
  [ShotPaymentMethod.OTHERS]: 'その他'
};
