import { combineReducers } from 'redux';
import cashedURLReducer from './cashed-url-reducer';
import previousURLReducer from './previous-url-reducer';
import processReducer from './process-reducer';
import cashedSpaceReservationReducer from './cashed-space-reservation-reducer';

export default combineReducers({
  process: processReducer,
  previousURL: previousURLReducer,
  cashedURL: cashedURLReducer,
  cashedSpaceReservation: cashedSpaceReservationReducer
});
