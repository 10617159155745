import {
  DeleteContractRequest,
  DeleteContractResponse,
  DELETE_CONTRACT,
  FetchContractRequest,
  FetchContractResponse,
  FetchContractsByDatesRequest,
  FetchContractsByDatesResponse,
  FetchContractUsageRequest,
  FetchContractUsageResponse,
  FetchContractUsagesByDatesRequest,
  FetchContractUsagesByDatesResponse,
  FETCH_CONTRACT,
  FETCH_CONTRACTS_BY_DATES,
  FETCH_CONTRACT_USAGE,
  FETCH_CONTRACT_USAGES_BY_DATES,
  SaveContractMembersRequest,
  SaveContractMembersResponse,
  SaveContractRequest,
  SaveContractResponse,
  SAVE_CONTRACT,
  SAVE_CONTRACT_MEMBERS,
  SearchContractsRequest,
  SearchContractsResponse,
  SEARCH_CONTRACTS,
  SendInvitationToContractMembersRequest,
  SendInvitationToContractMembersResponse,
  SEND_INVITATION_TO_CONTRACT_MEMBERS,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class ContractRequest {
  public static saveContract = async (request: SaveContractRequest): Promise<SaveContractResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_CONTRACT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveContractMembers = async (
    request: SaveContractMembersRequest
  ): Promise<SaveContractMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_CONTRACT_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchContract = async (request: FetchContractRequest): Promise<FetchContractResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_CONTRACT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchContractsByDates = async (
    request: FetchContractsByDatesRequest
  ): Promise<FetchContractsByDatesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_CONTRACTS_BY_DATES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static searchContracts = async (request: SearchContractsRequest): Promise<SearchContractsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_CONTRACTS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchContractUsage = async (
    request: FetchContractUsageRequest
  ): Promise<FetchContractUsageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_CONTRACT_USAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchContractUsagesByDate = async (
    request: FetchContractUsagesByDatesRequest
  ): Promise<FetchContractUsagesByDatesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_CONTRACT_USAGES_BY_DATES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteContract = async (request: DeleteContractRequest): Promise<DeleteContractResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_CONTRACT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static sendInvitationToContractMembers = async (
    request: SendInvitationToContractMembersRequest
  ): Promise<SendInvitationToContractMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEND_INVITATION_TO_CONTRACT_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
