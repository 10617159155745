import { Base, Shot, ShotId, ShotPaymentMethod, UsageType, User } from '@atomica.co/irori';
import {
  Amount,
  Count,
  EMPTY,
  Name,
  Number,
  Option,
  parseFloat,
  parseInt,
  PersonInCharge,
  Remarks,
  ZERO
} from '@atomica.co/utils';
import { ConferenceUsageType, showUsageOption, UsageOption } from '../constants/base-config';
import { Hour } from '../enums/common-enum';
import { QueryParams } from '../models/path-model';
import ConferenceService from '../services/conference-service';
import PriceService from '../services/price-service';
import { toFullName } from '../utils/user-util';

export const returnBaseOnlyIfExisted = (base: string | undefined): Base | undefined => {
  if (!base) return undefined;
  return Object.values(Base).includes(base as Base) ? (base as Base) : undefined;
};

export const toBaseOptions = (): Option[] => {
  return Object.values(Base).filter(base => base !== Base.ALL);
};

export const toShot = (
  shotId: ShotId,
  usageType: UsageType,
  base: Base,
  user: User,
  usageOption?: UsageOption,
  conferenceUsageType?: ConferenceUsageType,
  conferenceUsageHours?: Hour,
  conferenceNumberOfPeople?: Count,
  conferenceOneDayUsage?: boolean,
  conferenceOneDayNumberOfPeople?: Count,
  receiptName?: Name,
  cardNo?: Number,
  totalPrice?: Amount,
  paymentMethod?: ShotPaymentMethod,
  personInCharge?: PersonInCharge,
  remarks?: Remarks
): Shot => {
  const taxExcludedUnitPrice = PriceService.calculateTaxExcludedUnitPrice(base, usageType, conferenceUsageType);
  const taxIncludedUnitPrice = PriceService.toTaxIncludedPrice(taxExcludedUnitPrice);
  const unitQuantity = PriceService.calculateUnitQuantity(usageType, conferenceUsageType, conferenceUsageHours);

  const taxExcludedOptionPrice = PriceService.calculateTaxExcludedOptionPrice(
    base,
    usageType,
    usageOption,
    conferenceUsageType,
    conferenceOneDayUsage
  );
  const taxIncludedOptionPrice = PriceService.toTaxIncludedPrice(taxExcludedOptionPrice);
  const optionQuantity = PriceService.calculateOptionQuantity(
    base,
    usageType,
    usageOption,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople
  );

  const taxExcludedTotalPrice = taxExcludedUnitPrice * unitQuantity + taxExcludedOptionPrice * optionQuantity;
  const taxIncludedTotalPrice = taxIncludedUnitPrice * unitQuantity + taxIncludedOptionPrice * optionQuantity;

  return {
    shotId,
    usageType,
    base,
    user,
    usageOption: showUsageOption(usageType) ? usageOption : (EMPTY as UsageOption),
    conferenceUsageType: usageType === UsageType.CONFERENCE ? (conferenceUsageType as string) : EMPTY,
    conferenceUsageHours: ConferenceService.returnEligibleIfConferenceRoomReserved(
      usageType,
      conferenceUsageType,
      conferenceUsageHours,
      ZERO
    ),
    conferenceNumberOfPeople: ConferenceService.returnEligibleIfConferenceRoomReserved(
      usageType,
      conferenceUsageType,
      conferenceNumberOfPeople,
      ZERO
    ),
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople: !!conferenceOneDayUsage
      ? ConferenceService.returnEligibleIfConferenceRoomReserved(
          usageType,
          conferenceUsageType,
          conferenceOneDayNumberOfPeople,
          ZERO
        )
      : ZERO,
    taxIncludedUnitPrice: parseInt(taxIncludedUnitPrice),
    taxExcludedUnitPrice: parseInt(taxExcludedUnitPrice),
    unitQuantity: parseFloat(unitQuantity),
    taxIncludedOptionPrice: parseInt(taxIncludedOptionPrice),
    taxExcludedOptionPrice: parseInt(taxExcludedOptionPrice),
    optionQuantity: parseInt(optionQuantity),
    taxIncludedTotalPrice: parseInt(taxIncludedTotalPrice),
    taxExcludedTotalPrice: parseInt(taxExcludedTotalPrice),
    totalPrice: !!totalPrice ? parseInt(totalPrice) : parseInt(taxIncludedTotalPrice),
    paymentMethod: paymentMethod!,
    receiptName,
    cardNo,
    personInCharge,
    remarks
  };
};

export const fromQueryParams = (queryParams: QueryParams, base: Base, staff: User, visitor: User): Shot => {
  return {
    shotId: queryParams.shotId!,
    usageType: queryParams.usageType!,
    base,
    user: visitor,
    usageOption: !!queryParams.usageOption ? queryParams.usageOption : (EMPTY as UsageOption),
    conferenceUsageType: !!queryParams.conferenceUsageType ? (queryParams.conferenceUsageType as string) : EMPTY,
    conferenceUsageHours: queryParams.conferenceUsageHours as any,
    conferenceNumberOfPeople: parseInt(queryParams.conferenceNumberOfPeople),
    conferenceOneDayUsage: queryParams.conferenceOneDayUsage === 'true',
    conferenceOneDayNumberOfPeople: parseInt(queryParams.conferenceOneDayNumberOfPeople),
    taxIncludedUnitPrice: parseInt(queryParams.taxIncludedUnitPrice),
    taxExcludedUnitPrice: parseInt(queryParams.taxExcludedUnitPrice),
    unitQuantity: parseFloat(queryParams.unitQuantity),
    taxIncludedOptionPrice: parseInt(queryParams.taxIncludedOptionPrice),
    taxExcludedOptionPrice: parseInt(queryParams.taxExcludedOptionPrice),
    optionQuantity: parseInt(queryParams.optionQuantity),
    taxIncludedTotalPrice: parseInt(queryParams.taxIncludedTotalPrice),
    taxExcludedTotalPrice: parseInt(queryParams.taxExcludedTotalPrice),
    totalPrice: parseInt(queryParams.totalPrice),
    paymentMethod: ShotPaymentMethod.CASH,
    receiptName: !!queryParams.receiptName ? decodeURIComponent(queryParams.receiptName) : EMPTY,
    cardNo: !!queryParams.cardNo ? queryParams.cardNo : EMPTY,
    personInCharge: toFullName(staff),
    remarks: !!queryParams.remarks ? queryParams.remarks : EMPTY
  };
};
