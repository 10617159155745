import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import React, { useEffect } from 'react';
import useCashedURL from '../redux/hooks/useCashedURL';
import usePath from '../redux/hooks/usePath';
import useUser from '../redux/hooks/useUser';
import { Path } from '../router/Routes';

const AuthGuard = (Component: React.FC<any>): React.FC<any> =>
  React.memo(() => {
    const unmountRef = useUnmountRef();
    const { getUser, existFirebase } = useUser();
    const { openBasePath } = usePath();
    const { saveCurrentURL } = useCashedURL();
    const [ready, setReady] = useSafeState<boolean>(unmountRef, false);

    const initialize = useSafeCallback(async () => {
      const user = await getUser();

      if (!user) {
        saveCurrentURL();
        const firebase = await existFirebase();
        openBasePath(!!firebase ? Path.REGISTER_ACCOUNT : Path.SIGN_IN);
        return;
      }

      if (!user.isActivated) {
        saveCurrentURL();
        openBasePath(Path.REGISTER_ACCOUNT);
        return;
      }

      setReady(true);
    }, [getUser, saveCurrentURL, existFirebase, openBasePath, setReady]);

    useEffect(() => {
      initialize();
    }, [initialize]);

    return ready ? <Component /> : null;
  });

export default AuthGuard;
