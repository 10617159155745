import { Component, theme } from '@atomica.co/components';
import { Access, AccessType } from '@atomica.co/irori';
import { Color, Language, toDateTimeStr, toUTC } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import { ACCESS_LABELS } from '../../../texts/access-text';

interface P {
  access: Access;
}

const AccessRow: React.FC<P> = React.memo(props => {
  const { access } = props;

  return (
    <Component className='access-row'>
      <Container>
        <Column color={theme.mixins.typography.fontColor.gray}>
          {toDateTimeStr(toUTC(new Date(access.accessDate))!, Language.JAPANESE)}
        </Column>

        <Column
          color={
            access.accessType === AccessType.IN
              ? theme.mixins.typography.fontColor.pink
              : theme.mixins.typography.fontColor.gray
          }
        >
          {ACCESS_LABELS[access.accessType]}
        </Column>
      </Container>
    </Component>
  );
});

export default AccessRow;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const Column = styled.div<{ color: Color }>`
  min-width: 40px;
  color: ${props => props.color};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  margin-right: ${theme.mixins.spacing * 2}px;
  user-select: none;
`;
