import { Base, UsageType } from '@atomica.co/irori';
import { Amount, Count, EMPTY, Label, ONE, Quantity, ZERO } from '@atomica.co/utils';
import {
  ConferenceUsageType,
  toConferenceOneDayPrice,
  toConferencePrice,
  toConferenceUsageTypePriceLabel,
  toUsageOptionPrice,
  toUsageOptionPriceLabel,
  toUsageOptionQuantity,
  toUsageTypePrice,
  UsageOption,
  USAGE_TYPE_PRICE_LABELS
} from '../constants/base-config';
import { TAX_RATE_PERCENT } from '../constants/price-const';
import { Hour } from '../enums/common-enum';
import { CONFERENCE_USAGE_HOURS_LABEL } from '../texts/common-text';
import { NUMBER_OF_PEOPLE_LABEL_ONE_DAY } from '../texts/shot-text';
import ConferenceService from './conference-service';

export default class PriceService {
  public static getTaxIncludedUnitPriceLabel = (
    base: Base,
    usageType: UsageType,
    conferenceUsageType: ConferenceUsageType | undefined
  ): Label => {
    switch (usageType) {
      case UsageType.ONE_DAY:
      case UsageType.ONE_HOUR:
      case UsageType.TWO_HOURS:
      case UsageType.THREE_HOURS:
        return USAGE_TYPE_PRICE_LABELS[usageType];

      case UsageType.CONFERENCE:
        return toConferenceUsageTypePriceLabel(base, conferenceUsageType);

      default:
        return EMPTY;
    }
  };

  public static calculateTaxExcludedUnitPrice = (
    base: Base,
    usageType: UsageType,
    conferenceUsageType: ConferenceUsageType | undefined
  ): Amount => {
    switch (usageType) {
      case UsageType.ONE_DAY:
      case UsageType.ONE_HOUR:
      case UsageType.TWO_HOURS:
      case UsageType.THREE_HOURS:
        return toUsageTypePrice(base, usageType);

      case UsageType.CONFERENCE:
        return toConferencePrice(base, conferenceUsageType);

      default:
        return ZERO;
    }
  };

  public static getUnitQuantityLabel = (
    usageType: UsageType,
    conferenceUsageType: ConferenceUsageType | undefined
  ): Label => {
    switch (usageType) {
      case UsageType.CONFERENCE:
        return ConferenceService.returnEligibleIfConferenceRoomReserved(
          usageType,
          conferenceUsageType,
          CONFERENCE_USAGE_HOURS_LABEL,
          EMPTY
        );

      default:
        return EMPTY;
    }
  };

  public static calculateUnitQuantity = (
    usageType: UsageType,
    conferenceUsageType: ConferenceUsageType | undefined,
    conferenceUsageHours: Hour | undefined
  ): Quantity => {
    switch (usageType) {
      case UsageType.CONFERENCE:
        const quantity = ConferenceService.returnEligibleIfConferenceRoomReserved(
          usageType,
          conferenceUsageType,
          conferenceUsageHours,
          ONE
        );
        return parseFloat(quantity);

      default:
        return ONE;
    }
  };

  public static getTaxIncludedOptionPriceLabel = (
    base: Base,
    usageType: UsageType,
    usageOption: UsageOption | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Label => {
    switch (usageType) {
      case UsageType.ONE_DAY:
      case UsageType.ONE_HOUR:
      case UsageType.TWO_HOURS:
      case UsageType.THREE_HOURS:
        return toUsageOptionPriceLabel(base, usageType, usageOption);

      case UsageType.CONFERENCE:
        return !!conferenceOneDayUsage ? USAGE_TYPE_PRICE_LABELS[UsageType.ONE_DAY] : EMPTY;

      default:
        return EMPTY;
    }
  };

  public static calculateTaxExcludedOptionPrice = (
    base: Base,
    usageType: UsageType,
    usageOption: UsageOption | undefined,
    conferenceUsageType: ConferenceUsageType | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Amount => {
    switch (usageType) {
      case UsageType.ONE_DAY:
      case UsageType.ONE_HOUR:
      case UsageType.TWO_HOURS:
      case UsageType.THREE_HOURS:
        return toUsageOptionPrice(base, usageType, usageOption);

      case UsageType.CONFERENCE:
        return !!conferenceUsageType && !!conferenceOneDayUsage
          ? toConferenceOneDayPrice(base, conferenceUsageType)
          : ZERO;

      default:
        return ZERO;
    }
  };

  public static getOptionQuantityLabel = (usageType: UsageType, conferenceOneDayUsage: boolean | undefined): Label => {
    switch (usageType) {
      case UsageType.CONFERENCE:
        return !!conferenceOneDayUsage ? NUMBER_OF_PEOPLE_LABEL_ONE_DAY : EMPTY;

      default:
        return EMPTY;
    }
  };

  public static calculateOptionQuantity = (
    base: Base,
    usageType: UsageType,
    usageOption: UsageOption | undefined,
    conferenceOneDayUsage: boolean | undefined,
    conferenceOneDayNumberOfPeople: Count | undefined
  ): Quantity => {
    switch (usageType) {
      case UsageType.ONE_DAY:
      case UsageType.ONE_HOUR:
      case UsageType.TWO_HOURS:
      case UsageType.THREE_HOURS:
        return toUsageOptionQuantity(base, usageType, usageOption);

      case UsageType.CONFERENCE:
        return !!conferenceOneDayUsage && !!conferenceOneDayNumberOfPeople ? conferenceOneDayNumberOfPeople : ZERO;

      default:
        return ZERO;
    }
  };

  public static toTaxIncludedPrice = (price: Amount): Amount => {
    return Math.round(price * (1 + TAX_RATE_PERCENT / 100));
  };
}
