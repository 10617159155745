import { Prefecture } from '@atomica.co/irori';
import { Hour, NumberOfPeople } from '../enums/common-enum';
import { Labels } from '../models/common-model';

export const CONFERENCE_USAGE_HOURS_LABEL = '利用時間';

export const HOURS_LABELS: Labels = {
  [Hour.ONE]: '１時間',
  [Hour.ONE_AND_A_HALF]: '１時間半',
  [Hour.TWO]: '２時間',
  [Hour.TWO_AND_A_HALF]: '２時間半',
  [Hour.THREE]: '３時間',
  [Hour.THREE_AND_A_HALF]: '３時間半',
  [Hour.FOUR]: '４時間',
  [Hour.FOUR_AND_A_HALF]: '４時間半',
  [Hour.FIVE]: '５時間',
  [Hour.FIVE_AND_A_HALF]: '５時間半',
  [Hour.SIX]: '６時間',
  [Hour.SIX_AND_A_HALF]: '６時間半',
  [Hour.SEVEN]: '７時間',
  [Hour.SEVEN_AND_A_HALF]: '７時間半',
  [Hour.EIGHT]: '８時間',
  [Hour.EIGHT_AND_A_HALF]: '８時間半',
  [Hour.NINE]: '９時間',
  [Hour.NINE_AND_A_HALF]: '９時間半',
  [Hour.TEN]: '１０時間',
  [Hour.TEN_AND_A_HALF]: '１０時間半',
  [Hour.ELEVEN]: '１１時間',
  [Hour.ELEVEN_AND_A_HALF]: '１１時間半',
  [Hour.TWELVE]: '１２時間'
};

export const NUMBER_OF_PEOPLE_LABELS: Labels = {
  [NumberOfPeople.ONE]: '１人',
  [NumberOfPeople.TWO]: '２人',
  [NumberOfPeople.THREE]: '３人',
  [NumberOfPeople.FOUR]: '４人',
  [NumberOfPeople.FIVE]: '５人',
  [NumberOfPeople.SIX]: '６人',
  [NumberOfPeople.SEVEN]: '７人',
  [NumberOfPeople.EIGHT]: '８人',
  [NumberOfPeople.NINE]: '９人',
  [NumberOfPeople.TEN]: '１０人',
  [NumberOfPeople.ELEVEN]: '１１人',
  [NumberOfPeople.TWELVE]: '１２人',
  [NumberOfPeople.THIRTEEN]: '１３人',
  [NumberOfPeople.FOURTEEN]: '１４人',
  [NumberOfPeople.FIFTEEN]: '１５人'
};

export const PREFECTURE_LABELS: Labels = {
  [Prefecture.HOKKAIDO]: '北海道',
  [Prefecture.AOMORI]: '青森',
  [Prefecture.IWATE]: '岩手',
  [Prefecture.MIYAGI]: '宮城',
  [Prefecture.AKITA]: '秋田',
  [Prefecture.YAMAGATA]: '山形',
  [Prefecture.FUKUSHIMA]: '福島',
  [Prefecture.IBARAKI]: '茨城',
  [Prefecture.TOCHIGI]: '栃木',
  [Prefecture.GUNMA]: '群馬',
  [Prefecture.SAITAMA]: '埼玉',
  [Prefecture.CHIBA]: '千葉',
  [Prefecture.TOKYO]: '東京',
  [Prefecture.KANAGAWA]: '神奈川',
  [Prefecture.NIIGATA]: '新潟',
  [Prefecture.TOYAMA]: '富山',
  [Prefecture.ISHIKAWA]: '石川',
  [Prefecture.FUKUI]: '福井',
  [Prefecture.YAMANASHI]: '山梨',
  [Prefecture.NAGANO]: '長野',
  [Prefecture.GIFU]: '岐阜',
  [Prefecture.SHIZUOKA]: '静岡',
  [Prefecture.AICHI]: '愛知',
  [Prefecture.MIE]: '三重',
  [Prefecture.SHIGA]: '滋賀',
  [Prefecture.KYOTO]: '京都',
  [Prefecture.OSAKA]: '大阪',
  [Prefecture.HYOGO]: '兵庫',
  [Prefecture.NARA]: '奈良',
  [Prefecture.WAKAYAMA]: '和歌山',
  [Prefecture.TOTTORI]: '鳥取',
  [Prefecture.SHIMANE]: '島根',
  [Prefecture.OKAYAMA]: '岡山',
  [Prefecture.HIROSHIMA]: '広島',
  [Prefecture.YAMAGUCHI]: '山口',
  [Prefecture.TOKUSHIMA]: '徳島',
  [Prefecture.KAGAWA]: '香川',
  [Prefecture.EHIME]: '愛媛',
  [Prefecture.KOCHI]: '高知',
  [Prefecture.FUKUOKA]: '福岡',
  [Prefecture.SAGA]: '佐賀',
  [Prefecture.NAGASAKI]: '長崎',
  [Prefecture.KUMAMOTO]: '熊本',
  [Prefecture.OITA]: '大分',
  [Prefecture.MIYAZAKI]: '宮崎',
  [Prefecture.KAGOSHIMA]: '鹿児島',
  [Prefecture.OKINAWA]: '沖縄'
};
