import { CashedSpaceReservationActionEnum } from '../../enums/action-enum';
import { CashedSpaceReservationAction } from '../../models/action-model';
import { CashedSpaceReservation, initialCashedSpaceReservation } from '../hooks/useCashedSpaceReservation';

const cashedSpaceReservationReducer = (
  spaceReservation: CashedSpaceReservation,
  action: CashedSpaceReservationAction
): CashedSpaceReservation => {
  if (spaceReservation === undefined) return initialCashedSpaceReservation;

  switch (action.type) {
    case CashedSpaceReservationActionEnum.SET:
      return action.cashedSpaceReservation!;

    case CashedSpaceReservationActionEnum.PURGE:
      return initialCashedSpaceReservation;

    default:
      return spaceReservation;
  }
};

export default cashedSpaceReservationReducer;
