import { ROOM, UsageType } from '@atomica.co/irori';
import { ConferenceUsageType } from '../constants/base-config';

export default class ConferenceService {
  public static returnEligibleIfConferenceRoomReserved = (
    usageType: UsageType,
    conferenceUsageType: ConferenceUsageType | undefined,
    eligible: any,
    nonEligible: any
  ): any => {
    if (usageType !== UsageType.CONFERENCE || !conferenceUsageType) {
      return nonEligible;
    }

    return (conferenceUsageType as string).includes(ROOM) ? eligible : nonEligible;
  };
}
