import {
  Language,
  Milliseconds,
  MILLI_SECOND,
  ONE,
  paddingZero,
  UNIX_TIME_ONE_HOUR,
  UNIX_TIME_ONE_MINUTE
} from '@atomica.co/utils';

export const toTimeStr = (msec: Milliseconds, lang?: Language): string => {
  const hours = Math.floor(msec / (UNIX_TIME_ONE_HOUR * MILLI_SECOND));
  const minutes = Math.round((msec % (UNIX_TIME_ONE_HOUR * MILLI_SECOND)) / (UNIX_TIME_ONE_MINUTE * MILLI_SECOND));

  if (!lang) {
    return `${hours < 10 ? paddingZero(hours, ONE) : hours}:${minutes < 10 ? paddingZero(minutes, ONE) : minutes}`;
  }

  switch (lang) {
    case Language.JAPANESE:
      return (
        `${hours < 10 ? paddingZero(hours, ONE) : hours}時間` +
        `${minutes < 10 ? paddingZero(minutes, ONE) : minutes}分`
      );

    default:
      throw new Error(`${lang} is out of target.`);
  }
};
