import {
  FetchAccessesByDatesRequest,
  FetchAccessesByDatesResponse,
  FetchAccessesRequest,
  FetchAccessesResponse,
  FETCH_ACCESSES,
  FETCH_ACCESSES_BY_DATES,
  SaveAccessRequest,
  SaveAccessResponse,
  SAVE_ACCESS,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class AccessRequest {
  public static saveAccess = async (request: SaveAccessRequest): Promise<SaveAccessResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_ACCESS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchAccesses = async (request: FetchAccessesRequest): Promise<FetchAccessesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_ACCESSES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchAccessesByDates = async (
    request: FetchAccessesByDatesRequest
  ): Promise<FetchAccessesByDatesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_ACCESSES_BY_DATES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
