import {
  FetchTodayUsersRequest,
  FetchTodayUsersResponse,
  FetchUserByExternalIdRequest,
  FetchUserByExternalIdResponse,
  FetchUserRequest,
  FetchUserResponse,
  FETCH_TODAY_USERS,
  FETCH_USER,
  FETCH_USER_BY_EXTERNAL_ID,
  SearchUsersRequest,
  SearchUsersResponse,
  SEARCH_USERS,
  SaveFirebaseRequest,
  SaveFirebaseResponse,
  SaveUserRequest,
  SaveUserResponse,
  SAVE_FIREBASE,
  SAVE_USER,
  toFuncName,
  UpdateUserQFaceRequest,
  UpdateUserQFaceResponse,
  UPDATE_USER_QFACE
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class UserRequest {
  public static saveUser = async (request: SaveUserRequest): Promise<SaveUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveFirebase = async (request: SaveFirebaseRequest): Promise<SaveFirebaseResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_FIREBASE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static updateUserQFace = async (request: UpdateUserQFaceRequest): Promise<UpdateUserQFaceResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(UPDATE_USER_QFACE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUser = async (request: FetchUserRequest): Promise<FetchUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUserByExternalId = async (
    request: FetchUserByExternalIdRequest
  ): Promise<FetchUserByExternalIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER_BY_EXTERNAL_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchTodayUsers = async (request: FetchTodayUsersRequest): Promise<FetchTodayUsersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_TODAY_USERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static searchUsers = async (request: SearchUsersRequest): Promise<SearchUsersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_USERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
