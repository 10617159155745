import { Component, theme } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import mojaco from './../../assets/mojaco/mojaco_sorry.png';

interface P extends RouteComponentProps {}

const NoPrivilege: React.FC<P> = React.memo(() => {
  return (
    <Component className='no-privilege'>
      <Container>
        <Image src={mojaco} />

        <Message>アクセスする権限がありません</Message>
      </Container>
    </Component>
  );
});

export default NoPrivilege;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 400px;
  height: 400px;
  margin: ${theme.mixins.spacing * 3}px;
`;

const Message = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: Mamelon, ${theme.mixins.typography.fontFamily};
`;
