import { Base, UsageType } from '@atomica.co/irori';
import { Amount, EMPTY, Label, Option, Price, Quantity, ZERO } from '@atomica.co/utils';
import { BaseInfo as BaseInfoModel, Labels, PriceInfo, QuantityInfo } from '../models/common-model';

/** BASE INFO */

export const BaseInfo: BaseInfoModel = {
  [Base.ALL]: {
    name: 'アトミカ全拠点',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-membership.appspot.com/o/base%2Fthumbnails%2Fall.png?alt=media&token=3a5624ac-57ff-48b4-8e5a-072c463db965'
  },
  [Base.MIYAZAKI]: {
    name: 'アトミカ宮崎',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-membership.appspot.com/o/base%2Fthumbnails%2Fmiyazaki.jpeg?alt=media&token=5d9ac15e-90fe-46f6-b0c2-181d19a8835e'
  },
  [Base.NOBEOKA]: {
    name: '延岡市\nコワーキングスペース',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-membership.appspot.com/o/base%2Fthumbnails%2Fmiyazaki.jpeg?alt=media&token=5d9ac15e-90fe-46f6-b0c2-181d19a8835e'
  },
  [Base.KITAKYUSHU]: {
    name: 'アトミカ北九州',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-membership.appspot.com/o/base%2Fthumbnails%2Fkitakyushu.jpeg?alt=media&token=4cf56aa5-a145-4f77-ac36-abe0d73cab73'
  },
  [Base.KYUKODAI]: {
    name: '九工大\nコワーキングスペース',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-membership.appspot.com/o/base%2Fthumbnails%2Fmiyazaki.jpeg?alt=media&token=5d9ac15e-90fe-46f6-b0c2-181d19a8835e'
  },
  [Base.KAGOSHIMA]: {
    name: 'SOUU',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/atomica-apps-production.appspot.com/o/common%2Fsouu.jpg?alt=media&token=95ad0c37-fb4f-4f05-88ab-9b5088584743'
  }
};

/** USAGE TYPE */

export const toUsageTypes = (base: Base | undefined): string[] => {
  if (!base) return [];

  switch (base) {
    case Base.MIYAZAKI:
      return [UsageType.ONE_DAY, UsageType.CONFERENCE, UsageType.EVENT, UsageType.GUEST, UsageType.PREVIEW];

    case Base.NOBEOKA:
      return [UsageType.ONE_DAY, UsageType.THREE_HOURS, UsageType.CONFERENCE, UsageType.EVENT, UsageType.PREVIEW];

    case Base.KITAKYUSHU:
      return [UsageType.ONE_DAY, UsageType.CONFERENCE, UsageType.EVENT, UsageType.GUEST, UsageType.PREVIEW];

    case Base.KYUKODAI:
      return [UsageType.COWORKING, UsageType.EVENT, UsageType.GUEST, UsageType.PREVIEW];

    case Base.KAGOSHIMA:
      return [
        UsageType.ONE_DAY,
        UsageType.ONE_HOUR,
        UsageType.TWO_HOURS,
        UsageType.THREE_HOURS,
        UsageType.CONFERENCE,
        UsageType.EVENT,
        UsageType.GUEST,
        UsageType.PREVIEW
      ];

    default:
      throw new Error(`${base} is out of target`);
  }
};

/** USAGE TYPE LABEL */

export const USAGE_TYPE_LABELS: Labels = {
  [UsageType.COWORKING]: 'コワーキング利用',
  [UsageType.ONE_DAY]: '1DAY利用',
  [UsageType.ONE_HOUR]: '1H利用',
  [UsageType.TWO_HOURS]: '2H利用',
  [UsageType.THREE_HOURS]: '3H利用',
  [UsageType.CONFERENCE]: '会議室利用',
  [UsageType.EVENT]: 'イベント参加',
  [UsageType.GUEST]: '会員様ゲスト',
  [UsageType.PREVIEW]: '内覧'
};

/** USAGE TYPE PRICE LABEL */

export const USAGE_TYPE_PRICE_LABELS: Labels = {
  [UsageType.ONE_DAY]: '1DAY利用料金(税込)',
  [UsageType.ONE_HOUR]: '1H利用料金(税込)',
  [UsageType.TWO_HOURS]: '2H利用料金(税込)',
  [UsageType.THREE_HOURS]: '3H利用料金(税込)'
};

/** USAGE TYPE PRICE */

const UsageTypePriceMiyazaki: PriceInfo = {
  [UsageType.ONE_DAY]: 1000
};

const UsageTypePriceNobeoka: PriceInfo = {
  [UsageType.ONE_DAY]: 1000,
  [UsageType.THREE_HOURS]: 500
};

const UsageTypePriceKitakyushu: PriceInfo = {
  [UsageType.ONE_DAY]: 1000
};

const UsageTypePriceKyukodai: PriceInfo = {};

const UsageTypePriceKagoshima: PriceInfo = {
  [UsageType.ONE_DAY]: 2000,
  [UsageType.THREE_HOURS]: 1500,
  [UsageType.TWO_HOURS]: 1000,
  [UsageType.ONE_HOUR]: 500
};

const UsageTypePriceByBase = {
  [Base.MIYAZAKI]: UsageTypePriceMiyazaki,
  [Base.NOBEOKA]: UsageTypePriceNobeoka,
  [Base.KITAKYUSHU]: UsageTypePriceKitakyushu,
  [Base.KYUKODAI]: UsageTypePriceKyukodai,
  [Base.KAGOSHIMA]: UsageTypePriceKagoshima
};

export const toUsageTypePrice = (base: Base, usageType: UsageType): Amount => {
  if (!base || !usageType) return ZERO;
  const pricesByBase = UsageTypePriceByBase[base];
  const price = !!pricesByBase ? pricesByBase[usageType] : ZERO;
  return !!price ? price : ZERO;
};

/** USAGE OPTION */

export const showUsageOption = (usageType: UsageType | undefined): boolean => {
  if (!usageType) return false;

  return (
    usageType === UsageType.ONE_DAY ||
    usageType === UsageType.ONE_HOUR ||
    usageType === UsageType.TWO_HOURS ||
    usageType === UsageType.THREE_HOURS
  );
};

enum UsageOptionMiyazakiOneDay {
  NONE = 'none',
  WOWD = 'wowd',
  TPT = 'tpt',
  LINE = 'line',
  MAIL = 'mail',
  NEW_WORK = 'new_work',
  WORKUS = 'workus',
  TIMEWORK = 'timework',
  IIOFFICE = 'iioffice'
}

const UsageOptionMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionMiyazakiOneDay
};

enum UsageOptionNobeokaOneDay {
  NONE = 'none',
  WITHIN_CITY = 'within_city'
}

enum UsageOptionNobeokaThreeHours {
  NONE = 'none',
  WITHIN_CITY = 'within_city'
}

const UsageOptionNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionNobeokaThreeHours
};

enum UsageOptionKitakyushuOneDay {
  NONE = 'none',
  JQ_CARD = 'jq_card',
  TANGA_TABLE = 'tanga_table',
  SAINT_CITY = 'saint_city',
  NEW_WORK = 'new_work',
  OTAMESHI = 'otameshi',
  FREE = 'free'
}

const UsageOptionKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionKitakyushuOneDay
};

const UsageOptionKyukodai = {};

const UsageOptionKagoshima = {};

export type UsageOption =
  | UsageOptionMiyazakiOneDay
  | UsageOptionNobeokaOneDay
  | UsageOptionNobeokaThreeHours
  | UsageOptionKitakyushuOneDay;

export const UsageOptionEnum = {
  ...UsageOptionMiyazakiOneDay,
  ...UsageOptionNobeokaOneDay,
  ...UsageOptionNobeokaThreeHours,
  ...UsageOptionKitakyushuOneDay
};

const UsageOptionByBase = {
  [Base.MIYAZAKI]: UsageOptionMiyazaki,
  [Base.NOBEOKA]: UsageOptionNobeoka,
  [Base.KITAKYUSHU]: UsageOptionKitakyushu,
  [Base.KYUKODAI]: UsageOptionKyukodai,
  [Base.KAGOSHIMA]: UsageOptionKagoshima
};

export const toUsageOptions = (base: Base | undefined, usageType: UsageType | undefined): Option[] => {
  if (!base || !usageType) return [];
  const optionsByBase = UsageOptionByBase[base];
  const optionsByUsageType = !!optionsByBase ? optionsByBase[usageType] : [];
  return !!optionsByUsageType ? Object.values(optionsByUsageType) : [];
};

/** USAGE OPTION LABEL */

const UsageOptionLabelMiyazakiOneDay: Labels = {
  [UsageOptionMiyazakiOneDay.NONE]: '特になし',
  [UsageOptionMiyazakiOneDay.WOWD]: 'ワウディ会員',
  [UsageOptionMiyazakiOneDay.TPT]: 'TPTランチ利用',
  [UsageOptionMiyazakiOneDay.LINE]: 'LINEクーポン利用',
  [UsageOptionMiyazakiOneDay.MAIL]: 'メルマガクーポン利用',
  [UsageOptionMiyazakiOneDay.NEW_WORK]: 'NewWork',
  [UsageOptionMiyazakiOneDay.WORKUS]: 'Workus',
  [UsageOptionMiyazakiOneDay.TIMEWORK]: 'TIMEWORK',
  [UsageOptionMiyazakiOneDay.IIOFFICE]: 'いいオフィス'
};

const UsageOptionLabelMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionLabelMiyazakiOneDay
};

const UsageOptionLabelNobeokaOneDay: Labels = {
  [UsageOptionNobeokaOneDay.NONE]: '特になし',
  [UsageOptionNobeokaOneDay.WITHIN_CITY]: '市内在住'
};

const UsageOptionLabelNobeokaThreeHours: Labels = {
  [UsageOptionNobeokaThreeHours.NONE]: '特になし',
  [UsageOptionNobeokaThreeHours.WITHIN_CITY]: '市内在住'
};

const UsageOptionLabelNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionLabelNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionLabelNobeokaThreeHours
};

const UsageOptionLabelKitakyushuOneDay: Labels = {
  [UsageOptionKitakyushuOneDay.NONE]: '特になし',
  [UsageOptionKitakyushuOneDay.JQ_CARD]: 'JQカード会員',
  [UsageOptionKitakyushuOneDay.TANGA_TABLE]: 'タンガテーブル割引',
  [UsageOptionKitakyushuOneDay.SAINT_CITY]: 'セントシティ従業員割引',
  [UsageOptionKitakyushuOneDay.NEW_WORK]: 'NewWork会員',
  [UsageOptionKitakyushuOneDay.OTAMESHI]: 'おためしサテライト利用',
  [UsageOptionKitakyushuOneDay.FREE]: 'クーポン利用'
};

const UsageOptionLabelKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionLabelKitakyushuOneDay
};

const UsageOptionLabelKyukodai = {};

const UsageOptionLabelKagoshima = {};

const UsageOptionLabelByBase = {
  [Base.MIYAZAKI]: UsageOptionLabelMiyazaki,
  [Base.NOBEOKA]: UsageOptionLabelNobeoka,
  [Base.KITAKYUSHU]: UsageOptionLabelKitakyushu,
  [Base.KYUKODAI]: UsageOptionLabelKyukodai,
  [Base.KAGOSHIMA]: UsageOptionLabelKagoshima
};

export const toUsageOptionLabels = (base: Base | undefined, usageType: UsageType | undefined): Labels => {
  if (!base || !usageType) return {};
  const labelsByBase = UsageOptionLabelByBase[base];
  const labelsByUsageType = !!labelsByBase ? labelsByBase[usageType] : {};
  return !!labelsByUsageType ? labelsByUsageType : {};
};

export const toUsageOptionLabel = (
  base: Base | undefined,
  usageType: UsageType | undefined,
  usageOption: UsageOption | undefined
): Label => {
  if (!base || !usageType || !usageOption) return EMPTY;
  const labelsByUsageType = toUsageOptionLabels(base, usageType);
  const label = !!labelsByUsageType ? labelsByUsageType[usageOption] : EMPTY;
  return !!label ? label : EMPTY;
};

/** USAGE OPTION PRICE */

const UsageOptionPriceMiyazakiOneDay: PriceInfo = {
  [UsageOptionMiyazakiOneDay.NONE]: 0,
  [UsageOptionMiyazakiOneDay.WOWD]: -200,
  [UsageOptionMiyazakiOneDay.TPT]: -500,
  [UsageOptionMiyazakiOneDay.LINE]: -200,
  [UsageOptionMiyazakiOneDay.MAIL]: -1000,
  [UsageOptionMiyazakiOneDay.NEW_WORK]: -1000,
  [UsageOptionMiyazakiOneDay.WORKUS]: -1000,
  [UsageOptionMiyazakiOneDay.TIMEWORK]: -1000,
  [UsageOptionMiyazakiOneDay.IIOFFICE]: -1000
};

const UsageOptionPriceMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionPriceMiyazakiOneDay
};

const UsageOptionPriceNobeokaOneDay: PriceInfo = {
  [UsageOptionNobeokaOneDay.NONE]: 0,
  [UsageOptionNobeokaOneDay.WITHIN_CITY]: -400
};

const UsageOptionPriceNobeokaThreeHours: PriceInfo = {
  [UsageOptionNobeokaThreeHours.NONE]: 0,
  [UsageOptionNobeokaThreeHours.WITHIN_CITY]: -200
};

const UsageOptionPriceNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionPriceNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionPriceNobeokaThreeHours
};

const UsageOptionPriceKitakyushuOneDay: PriceInfo = {
  [UsageOptionKitakyushuOneDay.NONE]: 0,
  [UsageOptionKitakyushuOneDay.JQ_CARD]: -200,
  [UsageOptionKitakyushuOneDay.TANGA_TABLE]: -500,
  [UsageOptionKitakyushuOneDay.SAINT_CITY]: -500,
  [UsageOptionKitakyushuOneDay.NEW_WORK]: -1000,
  [UsageOptionKitakyushuOneDay.OTAMESHI]: 0,
  [UsageOptionKitakyushuOneDay.FREE]: -1000
};

const UsageOptionPriceKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionPriceKitakyushuOneDay
};

const UsageOptionPriceKyukodai = {};

const UsageOptionPriceKagoshima = {};

const UsageOptionPriceByBase = {
  [Base.MIYAZAKI]: UsageOptionPriceMiyazaki,
  [Base.NOBEOKA]: UsageOptionPriceNobeoka,
  [Base.KITAKYUSHU]: UsageOptionPriceKitakyushu,
  [Base.KYUKODAI]: UsageOptionPriceKyukodai,
  [Base.KAGOSHIMA]: UsageOptionPriceKagoshima
};

export const toUsageOptionPrices = (base: Base | undefined, usageType: UsageType | undefined): PriceInfo => {
  if (!base || !usageType) return {};
  const pricesByBase = UsageOptionPriceByBase[base];
  const pricesByUsageType = !!pricesByBase ? pricesByBase[usageType] : {};
  return !!pricesByUsageType ? pricesByUsageType : {};
};

export const toUsageOptionPrice = (
  base: Base | undefined,
  usageType: UsageType | undefined,
  usageOption: UsageOption | undefined
): Amount => {
  if (!base || !usageType || !usageOption) return ZERO;
  const pricesByUsageType = toUsageOptionPrices(base, usageType);
  const price = !!pricesByUsageType ? pricesByUsageType[usageOption] : ZERO;
  return !!price ? price : ZERO;
};

/** USAGE OPTION PRICE LABEL */

const UsageOptionPriceLabelMiyazakiOneDay: Labels = {
  [UsageOptionMiyazakiOneDay.NONE]: '',
  [UsageOptionMiyazakiOneDay.WOWD]: 'ワウディ会員割引(税込)',
  [UsageOptionMiyazakiOneDay.TPT]: 'TPTランチ割引(税込)',
  [UsageOptionMiyazakiOneDay.LINE]: 'LINEクーポン割引(税込)',
  [UsageOptionMiyazakiOneDay.MAIL]: 'メルマガクーポン割引(税込)',
  [UsageOptionMiyazakiOneDay.NEW_WORK]: 'NewWork割引(税込)',
  [UsageOptionMiyazakiOneDay.WORKUS]: 'Workus割引(税込)',
  [UsageOptionMiyazakiOneDay.TIMEWORK]: 'TIMEWORK割引(税込)',
  [UsageOptionMiyazakiOneDay.IIOFFICE]: 'いいオフィス割引(税込)'
};

const UsageOptionPriceLabelMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelMiyazakiOneDay
};

const UsageOptionPriceLabelNobeokaOneDay: Labels = {
  [UsageOptionNobeokaOneDay.NONE]: '',
  [UsageOptionNobeokaOneDay.WITHIN_CITY]: '市内在住割引(税込)'
};

const UsageOptionPriceLabelNobeokaThreeHours: Labels = {
  [UsageOptionNobeokaThreeHours.NONE]: '',
  [UsageOptionNobeokaThreeHours.WITHIN_CITY]: '市内在住割引(税込)'
};

const UsageOptionPriceLabelNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionPriceLabelNobeokaThreeHours
};

const UsageOptionPriceLabelKitakyushuOneDay: Labels = {
  [UsageOptionKitakyushuOneDay.NONE]: '',
  [UsageOptionKitakyushuOneDay.JQ_CARD]: 'JQカード会員割引(税込)',
  [UsageOptionKitakyushuOneDay.TANGA_TABLE]: 'タンガテーブル割引(税込)',
  [UsageOptionKitakyushuOneDay.SAINT_CITY]: 'セントシティ従業員割引(税込)',
  [UsageOptionKitakyushuOneDay.NEW_WORK]: 'NewWork会員割引(税込)',
  [UsageOptionKitakyushuOneDay.OTAMESHI]: 'おためしサテライト割引(税込)',
  [UsageOptionKitakyushuOneDay.FREE]: '無料クーポン割引(税込)'
};

const UsageOptionPriceLabelKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelKitakyushuOneDay
};

const UsageOptionPriceLabelKyukodai = {};

const UsageOptionPriceLabelKagoshima = {};

const UsageOptionPriceLabelByBase = {
  [Base.MIYAZAKI]: UsageOptionPriceLabelMiyazaki,
  [Base.NOBEOKA]: UsageOptionPriceLabelNobeoka,
  [Base.KITAKYUSHU]: UsageOptionPriceLabelKitakyushu,
  [Base.KYUKODAI]: UsageOptionPriceLabelKyukodai,
  [Base.KAGOSHIMA]: UsageOptionPriceLabelKagoshima
};

export const toUsageOptionPriceLabels = (base: Base | undefined, usageType: UsageType | undefined): Labels => {
  if (!base || !usageType) return {};
  const labelsByBase = UsageOptionPriceLabelByBase[base];
  const labelsByUsageType = !!labelsByBase ? labelsByBase[usageType] : {};
  return !!labelsByUsageType ? labelsByUsageType : {};
};

export const toUsageOptionPriceLabel = (
  base: Base | undefined,
  usageType: UsageType | undefined,
  usageOption: UsageOption | undefined
): Label => {
  if (!base || !usageType || !usageOption) return EMPTY;
  const labelsByUsageType = toUsageOptionPriceLabels(base, usageType);
  const label = !!labelsByUsageType ? labelsByUsageType[usageOption] : EMPTY;
  return !!label ? label : EMPTY;
};

/** USAGE OPTION QUANTITY */

const UsageOptionQuantityMiyazakiOneDay: QuantityInfo = {
  [UsageOptionMiyazakiOneDay.NONE]: 0,
  [UsageOptionMiyazakiOneDay.WOWD]: 1,
  [UsageOptionMiyazakiOneDay.TPT]: 1,
  [UsageOptionMiyazakiOneDay.LINE]: 1,
  [UsageOptionMiyazakiOneDay.MAIL]: 1,
  [UsageOptionMiyazakiOneDay.NEW_WORK]: 1,
  [UsageOptionMiyazakiOneDay.WORKUS]: 1,
  [UsageOptionMiyazakiOneDay.TIMEWORK]: 1,
  [UsageOptionMiyazakiOneDay.IIOFFICE]: 1
};

const UsageOptionQuantityMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionQuantityMiyazakiOneDay
};

const UsageOptionQuantityNobeokaOneDay: QuantityInfo = {
  [UsageOptionNobeokaOneDay.NONE]: 0,
  [UsageOptionNobeokaOneDay.WITHIN_CITY]: 1
};

const UsageOptionQuantityNobeokaThreeHours: QuantityInfo = {
  [UsageOptionNobeokaThreeHours.NONE]: 0,
  [UsageOptionNobeokaThreeHours.WITHIN_CITY]: 1
};

const UsageOptionQuantityNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionQuantityNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionQuantityNobeokaThreeHours
};

const UsageOptionQuantityKitakyushuOneDay: QuantityInfo = {
  [UsageOptionKitakyushuOneDay.NONE]: 0,
  [UsageOptionKitakyushuOneDay.JQ_CARD]: 1,
  [UsageOptionKitakyushuOneDay.TANGA_TABLE]: 1,
  [UsageOptionKitakyushuOneDay.SAINT_CITY]: 1,
  [UsageOptionKitakyushuOneDay.NEW_WORK]: 1,
  [UsageOptionKitakyushuOneDay.OTAMESHI]: 1,
  [UsageOptionKitakyushuOneDay.FREE]: 1
};

const UsageOptionQuantityKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionQuantityKitakyushuOneDay
};

const UsageOptionQuantityKyukodai = {};

const UsageOptionQuantityKagoshima = {};

const UsageOptionQuantityByBase = {
  [Base.MIYAZAKI]: UsageOptionQuantityMiyazaki,
  [Base.NOBEOKA]: UsageOptionQuantityNobeoka,
  [Base.KITAKYUSHU]: UsageOptionQuantityKitakyushu,
  [Base.KYUKODAI]: UsageOptionQuantityKyukodai,
  [Base.KAGOSHIMA]: UsageOptionQuantityKagoshima
};

export const toUsageOptionQuantities = (base: Base | undefined, usageType: UsageType | undefined): QuantityInfo => {
  if (!base || !usageType) return {};
  const quantitiesByBase = UsageOptionQuantityByBase[base];
  const quantitiesByUsageType = !!quantitiesByBase ? quantitiesByBase[usageType] : {};
  return !!quantitiesByUsageType ? quantitiesByUsageType : {};
};

export const toUsageOptionQuantity = (
  base: Base | undefined,
  usageType: UsageType | undefined,
  usageOption: UsageOption | undefined
): Quantity => {
  if (!base || !usageType || !usageOption) return ZERO;
  const quantitiesByUsageType = toUsageOptionQuantities(base, usageType);
  const quantity = !!quantitiesByUsageType ? quantitiesByUsageType[usageOption] : ZERO;
  return !!quantity ? quantity : ZERO;
};

/** CONFERENCE USAGE TYPE */

enum ConferenceUsageTypeMiyazaki {
  ROOM_FOR_THREE = 'room_for_three',
  ROOM_FOR_SIX = 'room_for_six',
  ROOM_FOR_EIGHT = 'room_for_eight',
  ROOM_FOR_LARGE = 'room_for_large',
  ROOM_FOR_KUMANOMA = 'room_for_kumanoma',
  GUEST = 'guest'
}

enum ConferenceUsageTypeNobeoka {
  ROOM_ONE = 'room_one',
  ROOM_TWO = 'room_two',
  ROOM_THREE = 'room_three',
  ROOM_FOUR = 'room_four',
  GUEST = 'guest'
}

enum ConferenceUsageTypeKitakyushu {
  ROOM_FOR_FOUR = 'room_for_four',
  ROOM_FOR_EIGHT = 'room_for_eight',
  ROOM_FOR_TWELVE = 'room_for_twelve',
  GUEST = 'guest'
}

enum ConferenceUsageTypeKyukodai {}

enum ConferenceUsageTypeKagoshima {
  ROOM = 'room',
  GUEST = 'guest'
}

export type ConferenceUsageType =
  | ConferenceUsageTypeMiyazaki
  | ConferenceUsageTypeNobeoka
  | ConferenceUsageTypeKitakyushu
  | ConferenceUsageTypeKyukodai
  | ConferenceUsageTypeKagoshima;

export const ConferenceUsageTypeEnum = {
  ...ConferenceUsageTypeMiyazaki,
  ...ConferenceUsageTypeNobeoka,
  ...ConferenceUsageTypeKitakyushu,
  ...ConferenceUsageTypeKyukodai,
  ...ConferenceUsageTypeKagoshima
};

const ConferenceUsageTypeByBase = {
  [Base.MIYAZAKI]: ConferenceUsageTypeMiyazaki,
  [Base.NOBEOKA]: ConferenceUsageTypeNobeoka,
  [Base.KITAKYUSHU]: ConferenceUsageTypeKitakyushu,
  [Base.KYUKODAI]: ConferenceUsageTypeKyukodai,
  [Base.KAGOSHIMA]: ConferenceUsageTypeKagoshima
};

export const toConferenceUsageTypes = (base: Base | undefined): Option[] => {
  if (!base) return [];
  const optionsByBase = ConferenceUsageTypeByBase[base];
  return !!optionsByBase ? Object.values(optionsByBase) : [];
};

/** CONFERENCE LABEL */

const ConferenceUsageTypeLabelMiyazaki: Labels = {
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_THREE]: '3名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_SIX]: '6名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_EIGHT]: '8名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_LARGE]: '大会議室',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_KUMANOMA]: '熊の間',
  [ConferenceUsageTypeMiyazaki.GUEST]: 'ゲスト利用'
};

const ConferenceUsageTypeLabelNobeoka: Labels = {
  [ConferenceUsageTypeNobeoka.ROOM_ONE]: 'ミーティングルーム1',
  [ConferenceUsageTypeNobeoka.ROOM_TWO]: 'ミーティングルーム2',
  [ConferenceUsageTypeNobeoka.ROOM_THREE]: 'ミーティングルーム3',
  [ConferenceUsageTypeNobeoka.ROOM_FOUR]: 'ミーティングルーム4',
  [ConferenceUsageTypeNobeoka.GUEST]: 'ゲスト利用'
};

const ConferenceUsageTypeLabelKitakyushu: Labels = {
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_FOUR]: '4名部屋',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_EIGHT]: '8名部屋',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_TWELVE]: '12名部屋',
  [ConferenceUsageTypeKitakyushu.GUEST]: 'ゲスト利用'
};

const ConferenceUsageTypeLabelKyukodai: Labels = {};

const ConferenceUsageTypeLabelKagoshima: Labels = {
  [ConferenceUsageTypeKagoshima.ROOM]: '会議室',
  [ConferenceUsageTypeKagoshima.GUEST]: 'ゲスト利用'
};

const ConferenceUsageTypeLabelByBase = {
  [Base.MIYAZAKI]: ConferenceUsageTypeLabelMiyazaki,
  [Base.NOBEOKA]: ConferenceUsageTypeLabelNobeoka,
  [Base.KITAKYUSHU]: ConferenceUsageTypeLabelKitakyushu,
  [Base.KYUKODAI]: ConferenceUsageTypeLabelKyukodai,
  [Base.KAGOSHIMA]: ConferenceUsageTypeLabelKagoshima
};

export const toConferenceUsageTypeLabels = (base: Base | undefined): Labels => {
  if (!base) return {};
  const labelsByBase = ConferenceUsageTypeLabelByBase[base];
  return !!labelsByBase ? labelsByBase : {};
};

export const toConferenceUsageTypeLabel = (
  base: Base | undefined,
  conferenceUsageType: ConferenceUsageType | undefined
): Label => {
  if (!base || !conferenceUsageType) return EMPTY;
  const labelsByBase = toConferenceUsageTypeLabels(base);
  const label = !!labelsByBase ? labelsByBase[conferenceUsageType] : EMPTY;
  return !!label ? label : EMPTY;
};

/** CONFERENCE PRICE LABEL */

const ConferenceUsageTypePriceLabelMiyazaki: Labels = {
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_THREE]: '3人部屋利用料金(税込)',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_SIX]: '6人部屋利用料金(税込)',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_EIGHT]: '8人部屋利用料金(税込)',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_LARGE]: '大部屋利用料金(税込)',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_KUMANOMA]: '熊の間利用料金(税込)',
  [ConferenceUsageTypeMiyazaki.GUEST]: ''
};

const ConferenceUsageTypePriceLabelNobeoka: Labels = {
  [ConferenceUsageTypeNobeoka.ROOM_ONE]: 'ミーティングルーム1利用料金(税込)',
  [ConferenceUsageTypeNobeoka.ROOM_TWO]: 'ミーティングルーム2利用料金(税込)',
  [ConferenceUsageTypeNobeoka.ROOM_THREE]: 'ミーティングルーム3利用料金(税込)',
  [ConferenceUsageTypeNobeoka.ROOM_FOUR]: 'ミーティングルーム4利用料金(税込)',
  [ConferenceUsageTypeNobeoka.GUEST]: ''
};

const ConferenceUsageTypePriceLabelKitakyushu: Labels = {
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_FOUR]: '4人部屋利用料金(税込)',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_EIGHT]: '8人部屋利用料金(税込)',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_TWELVE]: '12人部屋利用料金(税込)',
  [ConferenceUsageTypeKitakyushu.GUEST]: ''
};

const ConferenceUsageTypePriceLabelKyukodai: Labels = {};

const ConferenceUsageTypePriceLabelKagoshima: Labels = {
  [ConferenceUsageTypeKagoshima.ROOM]: '会議室利用料金(税込)',
  [ConferenceUsageTypeKagoshima.GUEST]: ''
};

const ConferenceUsageTypePriceLabelByBase = {
  [Base.MIYAZAKI]: ConferenceUsageTypePriceLabelMiyazaki,
  [Base.NOBEOKA]: ConferenceUsageTypePriceLabelNobeoka,
  [Base.KITAKYUSHU]: ConferenceUsageTypePriceLabelKitakyushu,
  [Base.KYUKODAI]: ConferenceUsageTypePriceLabelKyukodai,
  [Base.KAGOSHIMA]: ConferenceUsageTypePriceLabelKagoshima
};

export const toConferenceUsageTypePriceLabels = (base: Base | undefined): Labels => {
  if (!base) return {};
  const labelsByBase = ConferenceUsageTypePriceLabelByBase[base];
  return !!labelsByBase ? labelsByBase : {};
};

export const toConferenceUsageTypePriceLabel = (
  base: Base | undefined,
  conferenceUsageType: ConferenceUsageType | undefined
): Label => {
  if (!base || !conferenceUsageType) return EMPTY;
  const labelsByBase = toConferenceUsageTypePriceLabels(base);
  const label = !!labelsByBase ? labelsByBase[conferenceUsageType] : EMPTY;
  return !!label ? label : EMPTY;
};

/** CONFERENCE PRICE */

const ConferencePriceMiyazaki: PriceInfo = {
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_THREE]: 1500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_SIX]: 2000,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_EIGHT]: 2500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_LARGE]: 3000,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_KUMANOMA]: 3000,
  [ConferenceUsageTypeMiyazaki.GUEST]: 0
};

const ConferencePriceNobeoka: PriceInfo = {
  [ConferenceUsageTypeNobeoka.ROOM_ONE]: 400,
  [ConferenceUsageTypeNobeoka.ROOM_TWO]: 150,
  [ConferenceUsageTypeNobeoka.ROOM_THREE]: 150,
  [ConferenceUsageTypeNobeoka.ROOM_FOUR]: 150,
  [ConferenceUsageTypeNobeoka.GUEST]: 0
};

const ConferencePriceKitakyushu: PriceInfo = {
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_FOUR]: 2200,
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_EIGHT]: 3400,
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_TWELVE]: 4200,
  [ConferenceUsageTypeKitakyushu.GUEST]: 0
};

const ConferencePriceKyukodai: PriceInfo = {};

const ConferencePriceKagoshima: PriceInfo = {
  [ConferenceUsageTypeKagoshima.ROOM]: 2000,
  [ConferenceUsageTypeKagoshima.GUEST]: 0
};

const ConferencePriceByBase = {
  [Base.MIYAZAKI]: ConferencePriceMiyazaki,
  [Base.NOBEOKA]: ConferencePriceNobeoka,
  [Base.KITAKYUSHU]: ConferencePriceKitakyushu,
  [Base.KYUKODAI]: ConferencePriceKyukodai,
  [Base.KAGOSHIMA]: ConferencePriceKagoshima
};

export const toConferencePrice = (
  base: Base | undefined,
  conferenceUsageType: ConferenceUsageType | undefined
): Amount => {
  if (!base || !conferenceUsageType) return ZERO;
  const pricesByBase = ConferencePriceByBase[base];
  const price = !!pricesByBase ? pricesByBase[conferenceUsageType] : ZERO;
  return !!price ? price : ZERO;
};

/** ONE DAY PRICE FOR CONFERENCE USER */

export const showConferenceOneDayUsage = (base: Base | undefined): boolean => {
  if (!base) return false;

  switch (base) {
    case Base.MIYAZAKI:
    case Base.KITAKYUSHU:
      return true;

    default:
      return false;
  }
};

const ConferenceOneDayPriceMiyazaki: PriceInfo = {
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_THREE]: 500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_SIX]: 500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_EIGHT]: 500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_LARGE]: 500,
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_KUMANOMA]: 500,
  [ConferenceUsageTypeMiyazaki.GUEST]: 0
};

const ConferenceOneDayPriceKitakyushu: PriceInfo = {
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_FOUR]: 500,
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_EIGHT]: 500,
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_TWELVE]: 500,
  [ConferenceUsageTypeKitakyushu.GUEST]: 0
};

const ConferenceOneDayPriceByBase = {
  [Base.MIYAZAKI]: ConferenceOneDayPriceMiyazaki,
  [Base.KITAKYUSHU]: ConferenceOneDayPriceKitakyushu
};

export const toConferenceOneDayPrice = (
  base: Base | undefined,
  conferenceUsageType: ConferenceUsageType | undefined
): Amount => {
  if (!base || !conferenceUsageType) return ZERO;
  const pricesByBase = ConferenceOneDayPriceByBase[base];
  const price = !!pricesByBase ? pricesByBase[conferenceUsageType] : ZERO;
  return !!price ? price : ZERO;
};

/** CONTRACT PLAN */

enum ContractPlanMiyazaki {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  ALL_DAYS = 'all_days',
  ROOM_FOR_TWO = 'room_for_two',
  ROOM_FOR_THREE = 'room_for_three',
  ROOM_FOR_FOUR = 'room_for_four',
  ROOM_FOR_SIX = 'room_for_six'
}

enum ContractPlanNobeoka {
  MONTHLY_PERSONAL_INSIDE_CITY = 'monthly_personal_inside_city',
  MONTHLY_PERSONAL_OUTSIDE_CITY = 'monthly_personal_outside_city',
  MONTHLY_COMPANY_INSIDE_CITY = 'monthly_company_inside_city',
  MONTHLY_COMPANY_OUTSIDE_CITY = 'monthly_company_outside_city',
  YEARLY_PERSONAL_INSIDE_CITY = 'yearly_personal_inside_city',
  YEARLY_PERSONAL_OUTSIDE_CITY = 'yearly_personal_outside_city',
  YEARLY_COMPANY_INSIDE_CITY = 'yearly_company_inside_city',
  YEARLY_COMPANY_OUTSIDE_CITY = 'yearly_company_outside_city'
}

enum ContractPlanKitakyushu {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  TWO_THOUSAND_HOURS = 'two_thousand_hours',
  ALL_DAYS = 'all_days',
  ROOM_FOR_ONE = 'room_for_one',
  ROOM_FOR_TWO = 'room_for_two',
  ROOM_FOR_FIVE = 'room_for_five',
  ROOM_FOR_SIX = 'room_for_six',
  ROOM_FOR_NINE = 'room_for_nine'
}

enum ContractPlanKyukodai {
  ROOM_FOR_G = 'room_for_g',
  ROOM_FOR_Y = 'room_for_y',
  ROOM_FOR_M = 'room_for_m'
}

enum ContractPlanKagoshima {
  PERSONAL = 'personal',
  COMPANY = 'company'
}

export type ContractPlan =
  | ContractPlanMiyazaki
  | ContractPlanNobeoka
  | ContractPlanKitakyushu
  | ContractPlanKyukodai
  | ContractPlanKagoshima;

export const ContractPlans = {
  ...ContractPlanMiyazaki,
  ...ContractPlanNobeoka,
  ...ContractPlanKitakyushu,
  ...ContractPlanKyukodai,
  ...ContractPlanKagoshima
};

const ContractPlanByBase = {
  [Base.MIYAZAKI]: ContractPlanMiyazaki,
  [Base.NOBEOKA]: ContractPlanNobeoka,
  [Base.KITAKYUSHU]: ContractPlanKitakyushu,
  [Base.KYUKODAI]: ContractPlanKyukodai,
  [Base.KAGOSHIMA]: ContractPlanKagoshima
};

export const toContractPlans = (base: Base | undefined): Option[] => {
  if (!base) return [];
  const optionsByBase = ContractPlanByBase[base];
  return !!optionsByBase ? Object.values(optionsByBase) : [];
};

/** CONTRACT PLAN LABEL */

const ContractPlanLabelMiyazaki: Labels = {
  [ContractPlanMiyazaki.ROOM_FOR_TWO]: '2名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_THREE]: '3名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_FOUR]: '4名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_SIX]: '6名部屋',
  [ContractPlanMiyazaki.TEN_DAYS]: '10Days',
  [ContractPlanMiyazaki.EIGHTY_HOURS]: '80時間',
  [ContractPlanMiyazaki.ONE_HUNDRED_SIXTY_HOURS]: '160時間',
  [ContractPlanMiyazaki.THREE_HUNDREDS_TWENTY_HOURS]: '320時間',
  [ContractPlanMiyazaki.ALL_DAYS]: 'AllDays'
};

const ContractPlanLabelNobeoka: Labels = {
  [ContractPlanNobeoka.MONTHLY_PERSONAL_INSIDE_CITY]: '個人(市内)・月契約',
  [ContractPlanNobeoka.MONTHLY_PERSONAL_OUTSIDE_CITY]: '個人(市外)・月契約',
  [ContractPlanNobeoka.MONTHLY_COMPANY_INSIDE_CITY]: '法人(市内)・月契約',
  [ContractPlanNobeoka.MONTHLY_COMPANY_OUTSIDE_CITY]: '法人(市外)・月契約',
  [ContractPlanNobeoka.YEARLY_PERSONAL_INSIDE_CITY]: '個人(市内)・年契約',
  [ContractPlanNobeoka.YEARLY_PERSONAL_OUTSIDE_CITY]: '個人(市外)・年契約',
  [ContractPlanNobeoka.YEARLY_COMPANY_INSIDE_CITY]: '法人(市内)・年契約',
  [ContractPlanNobeoka.YEARLY_COMPANY_OUTSIDE_CITY]: '法人(市外)・年契約'
};

const ContractPlanLabelKitakyushu: Labels = {
  [ContractPlanKitakyushu.ROOM_FOR_ONE]: '1名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_TWO]: '2名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_FIVE]: '5名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_SIX]: '6名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_NINE]: '9名部屋',
  [ContractPlanKitakyushu.TEN_DAYS]: '10Days',
  [ContractPlanKitakyushu.EIGHTY_HOURS]: '80時間',
  [ContractPlanKitakyushu.ONE_HUNDRED_SIXTY_HOURS]: '160時間',
  [ContractPlanKitakyushu.THREE_HUNDREDS_TWENTY_HOURS]: '320時間',
  [ContractPlanKitakyushu.TWO_THOUSAND_HOURS]: '2,000時間',
  [ContractPlanKitakyushu.ALL_DAYS]: 'AllDays'
};

const ContractPlanLabelKyukodai: Labels = {
  [ContractPlanKyukodai.ROOM_FOR_G]: 'GYMLABO ルームG',
  [ContractPlanKyukodai.ROOM_FOR_Y]: 'GYMLABO ルームY',
  [ContractPlanKyukodai.ROOM_FOR_M]: 'GYMLABO ルームM'
};

const ContractPlanLabelKagoshima: Labels = {
  [ContractPlanKagoshima.PERSONAL]: '個人',
  [ContractPlanKagoshima.COMPANY]: '法人'
};

export const ContractPlanLabelByBase = {
  [Base.MIYAZAKI]: ContractPlanLabelMiyazaki,
  [Base.NOBEOKA]: ContractPlanLabelNobeoka,
  [Base.KITAKYUSHU]: ContractPlanLabelKitakyushu,
  [Base.KYUKODAI]: ContractPlanLabelKyukodai,
  [Base.KAGOSHIMA]: ContractPlanLabelKagoshima
};

export const toContractPlanLabel = (base: Base | undefined, contractPlan: ContractPlan | undefined): Label => {
  if (!base || !contractPlan) return EMPTY;
  const labelsByBase = ContractPlanLabelByBase[base];
  const label = !!labelsByBase ? labelsByBase[contractPlan] : EMPTY;
  return !!label ? label : EMPTY;
};

/** CONTRACT PLAN PRICE */

export const CONTRACT_KEY_ISSUE_PRICE = 5000;

export const CONTRACT_LOCKER_PRICE = 500;

export const CONTRACT_ADDRESS_PRICE = 2000;

const ContractPlanPriceMiyazaki: PriceInfo = {
  [ContractPlanMiyazaki.TEN_DAYS]: 8000,
  [ContractPlanMiyazaki.EIGHTY_HOURS]: 10000,
  [ContractPlanMiyazaki.ONE_HUNDRED_SIXTY_HOURS]: 15000,
  [ContractPlanMiyazaki.THREE_HUNDREDS_TWENTY_HOURS]: 25000,
  [ContractPlanMiyazaki.ALL_DAYS]: 20000,
  [ContractPlanMiyazaki.ROOM_FOR_TWO]: 60000,
  [ContractPlanMiyazaki.ROOM_FOR_THREE]: 80000,
  [ContractPlanMiyazaki.ROOM_FOR_FOUR]: 90000,
  [ContractPlanMiyazaki.ROOM_FOR_SIX]: 200000
};

const ContractPlanPriceNobeoka: PriceInfo = {
  [ContractPlanNobeoka.MONTHLY_PERSONAL_INSIDE_CITY]: 9000,
  [ContractPlanNobeoka.MONTHLY_PERSONAL_OUTSIDE_CITY]: 15000,
  [ContractPlanNobeoka.MONTHLY_COMPANY_INSIDE_CITY]: 27000,
  [ContractPlanNobeoka.MONTHLY_COMPANY_OUTSIDE_CITY]: 45000,
  [ContractPlanNobeoka.YEARLY_PERSONAL_INSIDE_CITY]: 90000,
  [ContractPlanNobeoka.YEARLY_PERSONAL_OUTSIDE_CITY]: 150000,
  [ContractPlanNobeoka.YEARLY_COMPANY_INSIDE_CITY]: 270000,
  [ContractPlanNobeoka.YEARLY_COMPANY_OUTSIDE_CITY]: 450000
};

const ContractPlanPriceKitakyushu: PriceInfo = {
  [ContractPlanKitakyushu.TEN_DAYS]: 8000,
  [ContractPlanKitakyushu.EIGHTY_HOURS]: 10000,
  [ContractPlanKitakyushu.ONE_HUNDRED_SIXTY_HOURS]: 18000,
  [ContractPlanKitakyushu.THREE_HUNDREDS_TWENTY_HOURS]: 32000,
  [ContractPlanKitakyushu.TWO_THOUSAND_HOURS]: 0,
  [ContractPlanKitakyushu.ALL_DAYS]: 20000,
  [ContractPlanKitakyushu.ROOM_FOR_ONE]: 35000,
  [ContractPlanKitakyushu.ROOM_FOR_TWO]: 70000,
  [ContractPlanKitakyushu.ROOM_FOR_FIVE]: 150000,
  [ContractPlanKitakyushu.ROOM_FOR_SIX]: 180000,
  [ContractPlanKitakyushu.ROOM_FOR_NINE]: 270000
};

const ContractPlanPriceKyukodai: PriceInfo = {
  [ContractPlanKyukodai.ROOM_FOR_G]: 0,
  [ContractPlanKyukodai.ROOM_FOR_Y]: 0,
  [ContractPlanKyukodai.ROOM_FOR_M]: 0
};

const ContractPlanPriceKagoshima: PriceInfo = {
  [ContractPlanKagoshima.PERSONAL]: 6000,
  [ContractPlanKagoshima.COMPANY]: 30000
};

const ContractPlanPriceByBase = {
  [Base.MIYAZAKI]: ContractPlanPriceMiyazaki,
  [Base.NOBEOKA]: ContractPlanPriceNobeoka,
  [Base.KITAKYUSHU]: ContractPlanPriceKitakyushu,
  [Base.KYUKODAI]: ContractPlanPriceKyukodai,
  [Base.KAGOSHIMA]: ContractPlanPriceKagoshima
};

export const toContractPlanPrice = (base: Base | undefined, contractPlan: ContractPlan | undefined): Price => {
  if (!base || !contractPlan) return ZERO;
  const pricesByBase = ContractPlanPriceByBase[base];
  const price = !!pricesByBase ? pricesByBase[contractPlan] : ZERO;
  return !!price ? price : ZERO;
};
